import React from 'react'

export default function version() {

    return (
        <>
            <p
                className='mb-0'
                style={{ fontSize: "10px", color: "white" }}
            > © {new Date().getFullYear()}  V3.079 (14)</p>
        </>
    )
}
