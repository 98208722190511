import { Home, Clipboard, Anchor, Archive, 
    FileText, Lock, Clock,
    Percent,
    DollarSign,
    Mail,List,
    Users, Share2, Book } from 'react-feather'

export const MENU_DEFAULT = [
    {
        title: 'Inicio',
        icon: Home,
        path: '/inicio',
        type: 'link',
        active: true,
        code: 'hom-raiz'
    },
    {
        title: 'Bookings',
        icon: Anchor,
        path: '/bookings',
        type: 'link',
        active: false,
        code: 'bookings'
    },
    {
        title: 'Solicitud ARIM',
        icon: Clipboard,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/solicitudes-exportacion',
                title: 'Ingreso contenedor',
                type: 'link',
                code: 'soa-solicitudes-exportacion',
                icon: Clipboard
            },
            {
                path: '/consulta-contenedor-importacion',
                title: 'Retiro contenedor',
                type: 'link',
                code: 'soa-consulta-contenedor-importacion'
            },
            {
                path: '/retiro-vacios',
                title: 'Retiro vacíos por clasificación',
                type: 'link',
                code: 'soa-retiro-vacios'
            }
            // {
            //     path: '/llenado-de-contenedores',
            //     title: 'Llenado contenedores',
            //     type: 'link',
            //     code: 'soa-llenado-de-contenedores',
            //     icon: Clipboard
            // }
        ]
    },
    {
        title: 'Centro documentos',
        icon: Archive,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/centro-documentos-ingreso-contenedor',
                title: 'Ingreso contenedor',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-ingreso-contenedor'
            },
            {
                path: '/centro-documentos-retiro-contenedor',
                title: 'Retiro Contenedor',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-retiro-contenedor'
            },
            {
                path: '/centro-documentos-retiro-de-vacios',
                title: 'Retiro Vacíos Por Clasificación ',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-retiro-de-vacios'
            },
            {
                path: '/consulta-mandatos',
                title: 'Consulta mandatos',
                type: 'link',
                code: 'cdo-consulta-mandatos'
            },
            {
                path: '/transaccion-export',
                title: 'Transaccion ingreso',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-transacciones-ingreso'
            },
            {
                path: '/transaccion-import',
                title: 'Transaccion retiro',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-transacciones-retiro'
            },
            {
                path: '/transacciones-vacio',
                title: 'Transacciones Vacio',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-transacciones-vacios'
            }            
        ]
    },
    {
        title: 'Facturación',
        icon: Percent,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/facturacion-ingreso-contenedor',
                title: 'Ingreso contenedor',
                type: 'link',
                icon: Clipboard,
                code: 'fac-ingreso-contenedor'
            },
            {
                path: '/facturacion-retiro-contenedor',
                title: 'Retiro contenedor',
                type: 'link',
                code: 'fac-retiro-contenedor'
            },
            {
                path: '/facturacion-retiro-de-vacios',
                title: 'Retiro vacíos por clasificación',
                type: 'link',
                code: 'fac-retiro-de-vacios'
            }
        ]
    },    
    {
        title: 'Servicio al cliente',
        icon: Users,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/solicitudes-de-vinculacion',
                title: 'Solicitudes de vinculación',
                type: 'link',
                code: 'sac-solicitudes-de-vinculacion'
            },
            {
                path: '/clientes-credito',
                title: 'Clientes crédito',
                type: 'link',
                code: 'sac-clientes-credito'
            }
        ]
    },
    {
        title: 'Operaciones logística',
        icon: Share2,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/solicitudes-de-inspeccion',
                title: 'Solicitudes Actuaciones a la Carga',
                type: 'link',
                code: 'opl-solicitudes-de-inspeccion'
            },
            {
                path: '/solicitudes-de-ingreso-lleno-ops',
                title: 'Solicitud de Ingreso - Llenos',
                type: 'link',
                code: 'opl-solicitudes-de-ingreso-lleno-ops'
            }
            // {
            //     path: '/configuracion-de-horarios',
            //     title: 'Config horarios',
            //     type: 'link',
            //     code: 'opl-configuracion-de-horarios'
            // }
        ]
    },
    {
        title: 'Citas',
        icon: Clock,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/generacion-citas',
                title: 'Generacion de citas',
                type: 'link',
                code: 'cit-generacion-citas'
            }
            // {
            //     path: '/citas-ingreso-contenedor',
            //     title: 'Ingreso contenedor',
            //     type: 'link',
            //     code: 'cit-ingreso-contenedor'
            // },
            // {
            //     path: '/citas-retiro-contenedor',
            //     title: 'Retiro contenedor',
            //     type: 'link',
            //     code: 'cit-retiro-contenedor'
            // },
            // {
            //     path: '/citas-retiro-por-clasificacion',
            //     title: 'Retiro vacios por clasificación',
            //     type: 'link',
            //     code: 'cit-retiro-por-clasificacion'
            // }
        ]
    },
    // {
    //     title: 'Vinculación Usuarios',
    //     icon: UserCheck,
    //     type: 'sub',
    //     active: false,
    //     children: [
    //         {
    //             path: '/solicitud-vinculacion-usuario',
    //             title: 'Solicitud de vinculación',
    //             type: 'link',
    //             code: 'viu-solicitud-vinculacion-usuario'
    //         }
    //     ]
    // },
    {
        title: 'Solicitudes',
        icon: Book,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/reporte-solicitud-inspeccion',
                title: 'Inspeccion',
                type: 'link',
                code: 'sol-inspeccion' //solicitudes-de-inspeccion
            },
            {
                path: '/reporte-solicitud-de-vaciado',
                title: 'Llenado/vaciado',
                type: 'link',
                code: 'sol-vaciado' //solicitudes de vaciado
            },
            {
                path: '/reporte-solicitud-de-motonave',
                title: 'A la motonave',
                type: 'link',
                code: 'sol-motonave'
            },
            {
                path: '/reporte-de-solicitudes',
                title: 'Servicios varios',
                type: 'link',
                code: 'sol-genericas'
            }
        ]
    },
    // {
    //     title: 'Mandatos',
    //     icon: Clipboard,
    //     type: 'sub',
    //     active: false,
    //     children: [
    //         {
    //             path: '/reporte-solicitud-mandatos',
    //             title: 'Solicitudes',
    //             type: 'link',
    //             code: 'man-reporte-solicitud-mandatos'
    //         }
    //     ]
    // },
    {
        title: 'Reportes',
        icon: FileText,
        type: 'sub',
        active: false,
        children: [
            //  Tomado desde el exel Universal Query Reportes Portal Web 1.4  OK            
            {
                path: '/consulta-bookings-bl',
                title: 'Consulta Bookings Bl',
                type: 'link',
                code: 'rep-consulta-bookings-bl'
            },          
            {
                path: '/consulta-de-contenedores',
                title: 'Consulta contenedor',
                type: 'link',
                code: 'rep-inventario-de-contenedores'
            },      
            //  Tomado desde el exel Universal Query Reportes Portal Web 1.3  OK
            {
                path: '/consulta-de-contenedores-por-puerta',
                title: 'Consulta contenedores puerta',
                type: 'link',
                code: 'rep-consulta-contenedores-por-puerta'
            },                     

            //  Tomado desde el exel Universal Query Reportes Portal Web 1.6  OK
            {
                path: '/consulta-de-citas',
                title: 'Consulta citas',
                type: 'link',
                code: 'rep-consulta-de-citas'
            },             
            //  Tomado desde el exel Universal Query Reportes Portal Web 1.2  OK
            {
                path: '/consulta-de-inventario',
                title: 'Consulta inventario',
                type: 'link',
                code: 'rep-consulta-inventario'
            },
            // Tomado desde el exel Universal Query Reportes Portal Web 1.7
            {
                path: '/consulta-inspeccion',
                title: 'Consulta Solicitud Inspección',
                type: 'link',
                code: 'rep-consulta-inspeccion'
            },               
            // No estan en el exel Universal Query Reportes Portal Web  - OK
            {
                path: '/consulta-factura-pagos',  
                title: 'Consulta Facturas',
                type: 'link',
                code: 'rep-consulta-de-factura-de-pagos'
            },         

            //  Tomado desde el exel Universal Query Reportes Portal Web 1.1 - OK
         
            // Tomado desde el exel Universal Query Reportes Portal Web 1.5 - OK
            {
                path: '/reporte-mandatos',
                title: 'Consulta Mandatos',
                type: 'link',
                code: 'rep-mandatos'
            },
            {
                path: '/reporte-valores-aduaneros',
                title: 'Reporte Valores Aduaneros',
                type: 'link',
                icon: Clipboard,
                code: 'cdo-reporte-valores-aduaneros'
            } 
            // No estan en el exel Universal Query Reportes Portal Web este ya no
            // {
            //     path: '/ingreso-contenedores-por-puerta',
            //     title: 'Ingreso contenedores por puerta',
            //     type: 'link',
            //     code: 'rep-ingreso-contenedores-por-puerta'
            // },
            // ==================================


            // No estan en el exel Universal Query Reportes Portal Web
            // {
            //     path: '/consulta-bookings',
            //     title: 'Bookings por cliente',
            //     type: 'link',
            //     code: 'rep-consulta-bookings'
            // },
            // Tomado desde el exel Universal Query Reportes Portal Web 1.9
            // {
            //     path: '/reporte-programacion-de-buques',
            //     title: 'Programación de buques',
            //     type: 'link',
            //     code: 'rep-reporte-programacion-de-buques'
            // },
            // Tomado desde el exel Universal Query Reportes Portal Web 1.8
            // {
            //     path: '/disponibilidad-de-citas',
            //     title: 'disponibilidad de citas',
            //     type: 'link',
            //     code: 'rep-disponibilidad-de-inventario-de-contenedores'
            // },
        ]
    },
    {
        title: 'Seguridad',
        icon: Lock,
        type: 'sub',
        active: false,
        children: [
            {
                path: '/permisos-usuarios',
                title: 'Permisos de usuarios',
                type: 'link',
                code: 'seg-permisos-usuarios'
            },
            {
                path: '/usuarios-internos',
                title: 'Usuarios Internos',
                type: 'link',
                code: 'seg-usuarios-internos'
            },
            {
                path: '/send-password-recovery',
                title: 'Recuperación de Contraseña',
                type: 'link',
                code: 'seg-recuperacion-contraseña'
            },
            {
                path: '/configuracion-sistema',
                title: 'Configuracion De Sistema',
                type: 'link',
                code: 'seg-configuracion-sistema'
            },
            {
                path: '/email-group',
                title: 'Email Group',
                type: 'link',
                code: 'seg-email-group'
            },
            {
                path: '/permisos-usuarios-por-rol',
                title: 'Permisos por rol',
                type: 'link',
                code: 'seg-permisos-usuarios-por-rol'
            },
            {
                path: '/documentos-por-modalidad',
                title: 'Documentos por modalidad',
                type: 'link',
                code: 'seg-documentos-por-modalidad'
            },
            {
                path: '/tipo-de-solicitud',
                title: 'Tipo Solicitud',
                type: 'link',
                code: 'seg-tipo-solicitud'                
            },
            {
                path: '/lineas-navieras',
                title: 'Lineas Navieras',
                type: 'link',
                code: 'seg-lineas-navieras'                
            },
            {
                path: '/reporte-logs',
                title: 'Reportes Logs',
                type: 'link',
                code: 'seg-reporte-logs'                
            },
            {
                path: '/reporte-logs-appointment',
                title: 'Reportes Logs citas',
                type: 'link',
                code: 'seg-reporte-logs-appointment'                
            },
            {
                path: '/reporte-logs-logisticos',
                title: 'Reportes Logs Logisticos',
                type: 'link',
                code: 'seg-reporte-logs-logistics'                
            },
            {
                path: '/reporte-logs-message-key',
                title: 'Reporte de registrar clave de mensaje',
                type: 'link',
                code: 'seg-reporte-logs-message-key'                
            }

        ]
    },
    {
        title: 'Pago en línea',
        icon: DollarSign,
        path: '/pago-en-linea',
        type: 'link',
        active: false,
        code: 'ext-pago-en-linea'
    },    
    {
        title: 'Registra Email FE',
        icon: Mail,
        path: '/registra-email-fe',
        type: 'link',
        active: false,
        code: 'ext-registro-email-fe'
    },    
    {
        title: 'Solicita factura',
        icon: List,
        path: '/solicita-factura',
        type: 'link',
        active: false,
        code: 'ext-solicitud-factura'
    }        
]